import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import customerService from "../../Services/customerService";
import Cookies from "js-cookie";
import { LoadingDots } from "../../Components/LoadingDots";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
    const navigate = useNavigate()
    const [error, setError] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState({
        email: "",
        password: ""
    });

    const [errors, setErrors] = useState({
        email: false,
        password: false
    });

    const [regex, setRegex] = useState({
        email: /.{1,50}$/,
        password: /.{1,50}$/
    });

    const viewPassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleChange = (v, n) => {
        setError('');
        setData(d => ({ ...d, [n]: v }));
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }));
        }
    };

    const handleSubmit = (e) => {
        setError('');
        let hasError = false;
        Object.keys(data).map(v => {
            if (!regex[v]?.test(data[v])) {
                setErrors(prevState => ({ ...prevState, [v]: true }));
                hasError = true;
            }
        });

        if (hasError) {
            toast.error('Fill the complete form', { id: "0" });
        } else {
            setLoading(true);
            customerService.login(data).then(res => {
                let customerProfile = {
                    id: res.customer.id,
                    first_name: res.customer.first_name,
                    last_name: res.customer.last_name,
                    email: res.customer.email,
                    phone: res.customer.phone_number
                };
                localStorage.setItem("customer", JSON.stringify(customerProfile));
                toast.success('Login Successfully', { id: "1" });
                setLoading(false);
                Cookies.set('REJTLUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OLVNFQ1JFVC1LRVk=', res.token, {
                    secure: true,
                    sameSite: 'Lax',
                });
                navigate('/crm/orders', { replace: true });
            }).catch((error) => {
                setLoading(false);
                if (error.responseCode === 400) {
                    setError(error.message);
                }
                if (error.responseCode === 403) {
                    setError('Account Not Activated');
                }
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSubmit(e); // Trigger form submission manually
        }
    };

    return (
        <div className="flex flex-col md:flex-row lg:flex-row h-screen">
            <div className="w-[50%] bg-primary flex flex-col justify-center items-center">
                <div className="">
                    <img src="./DBS.png" alt="jaa bhai" />
                </div>
            </div>
            <div className="w-[100%] md:w-[50%] lg:w-[50%] flex flex-col justify-center items-center">
                <div>
                    <h1 className="flex justify-center font-extrabold text-4xl text-primary mb-10">
                        Login
                    </h1>
                    <div className="py-10">
                        <input
                            value={data.email}
                            name="email"
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            onKeyDown={handleKeyDown} // Listen for Enter key on email input
                            type="email"
                            placeholder="Email"
                            className={`border-b-2 mb-10 border-gray-200 focus:outline-none focus:border-primary w-full  ${errors['email'] ? "border-red-600" : "border-gray-300"}`}
                        />
                        {showPassword ? (
                            <div className="flex flex-row border-b-2 border-gray-200 ">
                                <input
                                    value={data.password}
                                    name="password"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    onKeyDown={handleKeyDown} // Listen for Enter key on password input
                                    type="text"
                                    placeholder="Password"
                                    className={`focus:outline-none focus:border-primary w-full  ${errors['password'] ? "border-red-600" : "border-gray-300"}`}
                                />
                                <FaEyeSlash className="hover:cursor-pointer" onClick={viewPassword} />
                            </div>
                        ) : (
                            <div className="flex flex-row border-b-2 border-gray-200 ">
                                <input
                                    value={data.password}
                                    name="password"
                                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                                    onKeyDown={handleKeyDown} // Listen for Enter key on password input
                                    type="password"
                                    placeholder="Password"
                                    className={`focus:outline-none focus:border-primary w-full  ${errors['password'] ? "border-red-600" : "border-gray-300"}`}
                                />
                                <FaEye className="hover:cursor-pointer" onClick={viewPassword} />
                            </div>
                        )}

                        <div className="flex justify-end text-primary text-sm hover:underline hover:cursor-pointer"
                            onClick={() => navigate('/forgotpassword')}>
                            Forgot Password?
                        </div>
                        <div className="h-6">
                            {error ? <p className="text-[13px] text-red-500">{error}</p> : ""}
                        </div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <button
                            onClick={() => handleSubmit()} // Submit onClick
                            className={`flex gap-4 h-10 items-center justify-center self-center w-[30%] rounded-xl py-2 shadow-lg text-sm font-bold text-primary bg-secondary hover:bg-primary-dark ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? <LoadingDots /> : <>Login</>}
                        </button>

                        <div className="mt-5">
                            <p className="font-semibold text-gray-300">
                                Don't have an account?{" "}
                                <Link className="text-primary hover:underline" to="/signup" replace={true}>
                                    Sign up
                                </Link>
                            </p>
                        </div>

                        <div className="mt-5"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
