import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import customerService from "../../Services/customerService";
import toast from "react-hot-toast";

const ConfirmPassword = () => {
    const navigate = useNavigate()

    const [data, setData] = useState({
        password: "",
        confirmPassword: ""
    })

    console.log(data)

    const params = new URLSearchParams(window.location.search)
    const token = params.get("t")


    const changePassword = async ()  => {
        if (data.password !== data.confirmPassword){
            toast.error("Password and Confirm password must be same")
        }
        else {

            try {
                const response = await customerService.newPassword({token: token, password: data.password})
                if (response.responseCode === 202) {
                    navigate("/login")
                } else {
                    toast.error("Not working ")
                }
            } catch (e) {
              console.log(e)
            }
        }
    }


    const handleChange = (value, name) => {
        setData(prev => ({ ...prev, [name]: value }));
    };


    return (
        <div className='bg-[#EBEBEB] h-screen'>
            <div className='w-[100%] flex flex-col items-center justify-center h-screen '>
                <div className='bg-white px-16 py-10 rounded-lg shadow-lg' >
                    <h1 className='text-3xl text-primary font-extrabold  text-center'>
                        Reset Your Password
                    </h1>

                    <div className='flex flex-row gap-2 items-center mt-6 text-left w-full '>
                        <label className='text-sm mr-4'> Enter Your New Password </label>
                        <input
                            type='password'
                            name='password'
                            value={data.password}
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder='Enter Your Email'
                            className='bg-slate-50 px-2 py-0.5 border border-gray-300 focus:border-gray-400 outline-none rounded-lg '
                        />
                    </div>
                    <div className='flex flex-row gap-2 items-center mt-4 text-left w-full'>
                        <label className='text-sm'> Confirm Your New Password </label>
                        <input
                            type='password'
                            name='confirmPassword'
                            value={data.confirmPassword}
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            placeholder='Confirm Your Password'
                            className='bg-slate-50 px-2 py-0.5 border border-gray-300 focus:border-gray-400 outline-none rounded-lg '
                        />
                    </div>

                    <div className='text-center mt-5'>
                        <button className='bg-primary py-2 px-3 text-white rounded-lg'
                            onClick={() => changePassword()}
                        >
                            Save Password
                        </button>
                    </div>
                    {/* <div className='text-center mt-3'>
                        <p className='text-green-600 hover:cursor-pointer hover:text-green-500'
                            onClick={() => navigate('/login')}
                        > Back to Login </p>
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default ConfirmPassword
