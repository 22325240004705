import React from "react";
import Search from "../../Components/Search";
import Cards from "../../Components/Cards";
import banner from "../../assests/web_banner.png";
import RegisterForm from "../../Components/RegisterForm";

const Home = () => {
    return (
        <div className="bg-gray-100">
            <div>
                <img src={banner} alt="" className="w-full h-auto hidden md:block"
                />
                <div className="md:absolute w-[100%] inset-0 flex flex-col md:items-end items-center top-[100px]">
                    <div className={`w-[100%] md:w-[55%] md:mr-10 lg:mr-20 mt-28 md:-mt-6 flex text-center md:text-left justify-center md:justify-start flex-col`}>
                        <h1 className={`lg:text-4xl md:text-2xl text-3xl text-primary font-extrabold uppercase`}>DBS Auto Spare Parts</h1>
                        <h1 className={`lg:text-md md:text-sm text-black font-[800] mt-5 uppercase`}>Find a large range of products</h1>
                        <p className={`lg:text-sm text-xs text-primary font-light mt-1 mr-50 px-10 md:mr-0 md:px-0`}>Your Order, our top priority. Delivered with speed, handled with care.<br className="hidden md:block" />Your Goods, our priority. where safe storage meets seamless logistics</p>
                        <Search />
                    </div>
                </div>
            </div>
            <div className=" w-full lg:-mt-32 flex justify-center">
                <div className="lg:shadow-xl w-full bottom-0 z-10 border-t-2 border-b-2 border-secondary lg:border-none bg-white lg:mx-80 mt-10 md:mt-20 lg:rounded-xl flex justify-center items-center">
                    <RegisterForm />
                </div>
            </div>
            <div className={`flex flex-col justify-center items-center mt-10`}>
                <h1 className="text-gray-500 font-[800] text-4xl uppercase mt-8">
                    Spare Parts
                </h1>
                <div className={`bg-secondary h-1 w-[300px] rounded-full`} />
                <p className={`lg:px-[300px] md:px-[150px] px-5 font-light text-center mt-2`}> We offer thousands of high-quality products at
                    competitive prices, helping you stock up and meet your customers' needs with ease and
                    reliability!</p>
            </div>
            <Cards />
        </div>
    );
};

export default Home
