import React, {
    useState,
    forwardRef,
    useRef,
    useImperativeHandle,
    useEffect,
} from "react";
import Modal from '@mui/material/Modal';
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { FaArrowLeftLong, FaXmark } from "react-icons/fa6";
import toast from "react-hot-toast";
import walletService from "../../Services/walletService";
import stripeService from "../../Services/stripeService";
import orderService from "../../Services/orderService";
const stripePromise = loadStripe(
    "pk_test_51NpsCXBA5mbdD8e2Tg8MVBCXtomGyF11MzP1eFRceziDGIOGxMwmjToCNFLQEc2zXeYnBUhk89oKcJ9ffXpSikqU00bObmoUIu"
);

const PaymentModal = ({ isOpen, onClose, selectedOrderId, trigger, setTrigger }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        paid_amount: "0",
    });

    const [errors, setErrors] = useState({
        paid_amount: false,
    });

    const [regex, setRegex] = useState({
        paid_amount: /.{1,50}$/,
    });
    const handleChange = (v, n) => {
        setData((d) => ({ ...d, [n]: v }));
        if (v.length > 0) {
            setErrors((prevState) => ({ ...prevState, [n]: false }));
        }
    };

    let payment = { success: false };
    const childRef = useRef(payment);

    const handleSubmit = async () => {
        if (data.paid_amount < 1) {
            toast.error("Please Enter Valid paid_amount");
            return;
        }
        let hasError = false;
        Object.keys(data).map((v) => {
            if (!regex[v]?.test(data[v])) {
                setErrors((prevState) => ({ ...prevState, [v]: true }));
                hasError = true;
            }
        });

        if (hasError) {
            toast.error("Please fill the form correctly", { id: "1" });
        } else {
            setLoading(true)
            const response = await childRef.current.pay();
            if (response.success) {
                orderService.recordPayment(selectedOrderId, data).then((res) => {
                    setLoading(false)
                    toast.success("Amount Charged Successfully", { id: "2" });
                    setData((prev) => ({ ...prev, paid_amount: 0 }));
                    setTrigger(!trigger)
                    onClose()
                });
            } else {
                toast.error("Unable to proceed payment", { id: "3" });
            }
            setLoading(false)
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='flex items-center justify-center'
        >
            <div
                className="w-[55vw]"><div>
                    
                    <div className="bg-white p-4 mx-20 rounded-xl shadow-md mt-5">
                    <div className="flex justify-end mr-5">
                        <button
                            className="flex justify-end self-end items-center px-1 text-black py-1 rounded-full ml-8 mt-4"
                            onClick={() => onClose()}
                        >
                            <FaXmark className="h-5 w-5" />
                        </button>
                    </div>
                        <h1 className="text-3xl text-primary font-extrabold flex justify-center mt-6">
                            Top Up Details
                        </h1>
                        <div className="flex flex-col items-center justify-center w-[80%] gap-3 mt-5 px-[3%] mx-auto">
                            <div className="w-full">
                                <label className="text-left tracking-wide text-grey-darker text-[1.2rem] font-[600] mb-[3px]">
                                    paid_amount
                                </label>
                                <input
                                    value={parseFloat(data.paid_amount).toFixed(2)}
                                    name="paid_amount"
                                    onChange={(e) =>
                                        handleChange(e.target.value, e.target.name)
                                    }
                                    className={`py-2 border-gray-200 focus:outline-none focus:border-primary w-full
px-4 bg-gray-200 bg-opacity-90 rounded-lg ${errors["paid_amount"] ? "border border-red-500" : ""
                                        } p-0.5`}
                                />
                            </div>
                            <div className="w-full">
                                <label className="text-left tracking-wide text-grey-darker text-[1.1rem] font-[600] mb-[3px]">
                                    Card Details
                                </label>
                                <Elements
                                    stripe={stripePromise}
                                    options={{
                                        clientSecret:
                                            "pi_3Q6lazBA5mbdD8e206s1wPwC_secret_ZOzBMzHBHfVaf9jGEaCrODDSJ",
                                    }}
                                >
                                    <Payment
                                        paid_amount={parseFloat(data.paid_amount).toFixed(2)}
                                        ref={childRef}
                                    />
                                </Elements>
                            </div>
                        </div>

                        <div className="flex justify-center mt-5 mb-5">
                            <button
                                className={`w-40 text-white font-bold py-2 ml-5 rounded-md mt-4 
                ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary'}`}
                                onClick={() => handleSubmit()}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Top Up'}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}


const Payment = forwardRef(({ paid_amount }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    useImperativeHandle(ref, () => ({
        async pay() {
            let price = parseInt(paid_amount.toString().replaceAll(".", ""));
            return await stripeService
                .createIntent({ price: price })
                .then(async (response) => {
                    if (!stripe || !elements) {
                        return { success: false };
                    }

                    const cardElement = elements.getElement(CardElement);

                    const { error, paymentIntent } = await stripe.confirmCardPayment(
                        response.clientSecret,
                        {
                            payment_method: {
                                card: cardElement,
                            },
                        }
                    );

                    if (error) {
                        return { success: false };
                    } else if (paymentIntent && paymentIntent.status === "succeeded") {
                        return { success: true };
                    }
                });
        },
    }));

    return (
        <CardElement
            options={{
                style: {
                    base: {
                        fontWeight: "700",
                        fontFamily: "inherit",
                        color: "#000000",
                        "::placeholder": {
                            color: "#afafaf",
                            fontWeight: "500",
                        },
                    },
                },
                hidePostalCode: true,
            }}
            className={`py-3 border border-gray-200 focus:outline-none focus:border-primary  w-full px-3 bg-gray-400 bg-opacity-20 rounded-lg p-0.5`}
        />
    );
});

export default PaymentModal
