import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import customerService from "../../Services/customerService";
import toast from "react-hot-toast";

const Otp = () => {

    const navigate = useNavigate()
    const [otp, setOtp] = useState('')

    const VerifyOTP = async () => {
        try {
            const response = await customerService.verify({ otp })
            if (response.responseCode === 200) {
                navigate("/confirmpassword?t="+response.token)
            } else {
                toast.error("Invalid OTP! Please enter the correct OTP")
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (

        <div className='bg-[#EBEBEB] h-screen'>
            <div className='w-[100%] flex flex-col items-center justify-center h-screen '>
                <div className='bg-white px-20 py-10 rounded-lg shadow-lg' >
                    <h1 className='text-3xl text-primary font-extrabold  text-center'>
                        OTP code verification
                    </h1>

                    <div className='flex flex-row gap-2 justify-center mt-4 '>

                        <input
                            type='text'
                            name='otp'
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder='Enter the OTP recieved on your Email'
                            className='w-72 bg-slate-50 px-2 py-1 border border-gray-300 focus:border-gray-400 outline-none rounded-lg text-center '
                            maxLength={6}

                        />

                    </div>

                    <div className='text-center mt-5'>
                        <button className='bg-primary p-2 text-white rounded-lg'
                            onClick={() => VerifyOTP()}
                        >

                            Reset Password
                        </button>
                    </div>
                    {/* <div className='text-center mt-3'>
                        <p className='text-green-600 hover:cursor-pointer hover:text-green-500'
                            onClick={() => navigate('/login')}
                        > Back to Login </p>
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default Otp
