import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import moment from "moment";
import { IoWallet } from "react-icons/io5";
import { FaArrowLeftLong, FaXmark } from "react-icons/fa6";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stripeService from "../../Services/stripeService";
import toast from "react-hot-toast";
import walletService from "../../Services/walletService";
import orderService from "../../Services/orderService";
import { LoadingDots } from "../../Components/LoadingDots";
const stripePromise = loadStripe(
  "pk_test_51NpsCXBA5mbdD8e2Tg8MVBCXtomGyF11MzP1eFRceziDGIOGxMwmjToCNFLQEc2zXeYnBUhk89oKcJ9ffXpSikqU00bObmoUIu"
);

const Accounts = () => {
  const customerProfile = JSON.parse(localStorage.getItem("customer"));
  const [loading, setLoading] = useState(false);
  const [ledger, setLedger] = useState([]);
  const [customer, setCustomer] = useState(null);
  const customerId = customerProfile?.id;

  const [trigger, setTrigger] = useState(true);
  const [balance, setBalance] = useState(0);

  const [paidAmount, setPaidAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [unpaid, setUnpaid] = useState(0);

  const [data, setData] = useState({
    amount: "0",
  });

  const [errors, setErrors] = useState({
    amount: false,
  });

  const [regex, setRegex] = useState({
    amount: /.{1,50}$/,
  });

  useEffect(() => {
    const getCustomerLedger = async () => {
      try {
        setLoading(true);
        const response = await orderService.fetchCustomerLedger(customerId);
        setCustomer(response.customer);
        setLedger(response.ledger);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching customer ledger:", error);
        setLoading(false);
      }
    };
    getCustomerLedger();
  }, []);

  useEffect(() => {
    walletService.fetchAmount(customerProfile.id).then((res) => {
      setBalance(res.wallet?.amount ? res.wallet.amount : 0);
    });
  }, [trigger]);

  useEffect(() => {
    orderService.fetchCustomerPaid(customerProfile.id).then((res) => {
      setPaidAmount(res.orderPaid?.paid_amount ? res.orderPaid.paid_amount : '0.00');
    });

    orderService.fetchCustomerTotal(customerProfile.id).then((res) => {
      setTotal(res.orderTotal?.amount ? res.orderTotal.amount : '0.00');
    });

    orderService.fetchCustomerUnpaid(customerProfile.id).then((res) => {
      setUnpaid(res.orders[0]?.balance ? res.orders[0].balance : '0.00');
    });
  }, []);

  const handleChange = (v, n) => {
    setData((d) => ({ ...d, [n]: v }));
    if (v.length > 0) {
      setErrors((prevState) => ({ ...prevState, [n]: false }));
    }
  };

  let payment = { success: false };
  const childRef = useRef(payment);

  const handleSubmit = async () => {
    if (data.amount === 0) {
      toast.error("Please Enter Valid Amount");
      return;
    }
    let hasError = false;
    Object.keys(data).map((v) => {
      if (!regex[v]?.test(data[v])) {
        setErrors((prevState) => ({ ...prevState, [v]: true }));
        hasError = true;
      }
    });

    if (hasError) {
      toast.error("Please fill the form correctly", { id: "1" });
    } else {
      const response = await childRef.current.pay();
      if (response.success) {
        walletService.chargeAmount(4, data).then((res) => {
          toast.success("Amount Charged Successfully", { id: "2" });
          setShowTopUpScreen(false);
          setTrigger(!trigger);
          setData((prev) => ({ ...prev, amount: 0 }));
        });
      } else {
        toast.error("Unable to proceed payment", { id: "3" });
      }
    }
  };

  const [showTopUpScreen, setShowTopUpScreen] = useState(false);
  return (
    <div>
      <h1 className="flex justify-center text-center font-extrabold text-4xl text-primary mt-10">
        Account
      </h1>
      <div className="flex flex-col bg-white gap-5 md:m-10 m-4 mt-4 md:px-32 md:py-20 rounded-2xl">
        <div className="w-[100%] text-white md:bg-gray-100 bg-white p-6 rounded-xl">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-3xl text-gray-700">Balance</h1>
            <IoWallet className="text-3xl text-gray-700" />
          </div>
          <div className="flex justify-center mt-10">
            <h1 className="text-center font-medium text-green-900 text-7xl">
              {" "}
              {balance ? balance : <LoadingDots />}
            </h1>
            <h1 className="font-extrabold text-gray-500">AED</h1>
          </div>
        </div>

        {showTopUpScreen ? (
          <div className="bg-gray-100 pb-12 px-12 pt-2 md:rounded-md transform transition duration-500 ease-in-out -translate-y-10 opacity-0 animate-slide-in">
            <div className="flex justify-end -mr-5">
              <button
                className="flex justify-end self-end items-center px-1 text-black py-1 rounded-full ml-8 mt-4"
                onClick={() => setShowTopUpScreen(false)}
              >
                <FaXmark className="h-5 w-5" />
              </button>
            </div>
            <div className="bg-white p-4 mx-20 rounded-xl shadow-md mt-5">
              <h1 className="text-3xl text-primary font-extrabold flex justify-center mt-6">
                Top Up Details
              </h1>
              <div className="flex flex-col items-center justify-center w-[80%] gap-3 mt-5 px-[3%] mx-auto">
                <div className="w-full">
                  <label className="text-left tracking-wide text-grey-darker text-[1.2rem] font-[600] mb-[3px]">
                    Amount
                  </label>
                  <input
                    value={parseFloat(data.amount).toFixed(2)}
                    name="amount"
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    className={`py-2 border-gray-200 focus:outline-none focus:border-primary w-full
      px-4 bg-gray-200 bg-opacity-90 rounded-lg ${errors["amount"] ? "border border-red-500" : ""
                      } p-0.5`}
                  />
                </div>
                <div className="w-full">
                  <label className="text-left tracking-wide text-grey-darker text-[1.1rem] font-[600] mb-[3px]">
                    Card Details
                  </label>
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret:
                        "pi_3Q6lazBA5mbdD8e206s1wPwC_secret_ZOzBMzHBHfVaf9jGEaCrODDSJ",
                    }}
                  >
                    <Payment
                      amount={parseFloat(data.amount).toFixed(2)}
                      ref={childRef}
                    />
                  </Elements>
                </div>
              </div>

              <div className="flex justify-center mt-5 mb-5">
                <button
                  className="bg-primary w-40 text-white font-bold py-2 ml-5 rounded-md mt-4"
                  onClick={() => handleSubmit()}
                >
                  Top Up
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="grid grid-cols-3 md:gap-4 gap-1 mt-10">
          <div className="bg-slate-800 shadow-lg p-4 rounded-xl">
            <h1 className="font-bold text-sm md:text-base text-gray-400">
              Total Order Amount
            </h1>
            <div className="flex justify-center mt-4 md:mt-8 mb-4">
              <h1 className="flex justify-center text-center font-medium text-white text-2xl md:text-4xl">
                {total ? total : <LoadingDots />}
              </h1>
              <h1 className="font-extrabold text-gray-500 text-xs md:text-sm">
                AED
              </h1>
            </div>
          </div>

          <div className="bg-slate-800 shadow-lg p-4 rounded-xl">
            <h1 className="font-bold text-sm md:text-base text-gray-400">
              Paid Amount
            </h1>
            <div className="flex justify-center mt-8 mb-4">
              <h1 className="flex justify-center text-center font-medium text-white text-2xl md:text-4xl">
                {paidAmount ? paidAmount : <LoadingDots />}
              </h1>
              <h1 className="font-extrabold text-gray-500 text-xs md:text-sm">
                AED
              </h1>
            </div>
          </div>

          <div className="bg-slate-800 shadow-lg p-4 rounded-xl">
            <h1 className="font-bold text-sm md:text-base text-gray-400">
              Pending Payment
            </h1>
            <div className="flex justify-center mt-8 mb-4">
              <h1 className="flex justify-center text-center font-medium text-white text-2xl md:text-4xl">
                {unpaid ? unpaid : <LoadingDots />}
              </h1>
              <h1 className="font-extrabold text-gray-500 text-xs md:text-sm">
                AED
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Ledger Component */}
      <div className="px-10 py-1">
        {customer && (
          <div>
            {/* <div className="bg-white shadow-lg px-8 py-4 mt-2 border-gray-300 border-2 rounded-3xl">
              <div className="grid grid-cols-3 items-top">
                <div>
                  <div className="text-primary text-lg font-semibold flex flex-row items-center gap-2">
                    <h1>{customer.first_name}</h1>
                    <h1 className="text-sm border border-gray-400 px-3 rounded-full text-blue-600">{customer.customer_type}</h1>
                  </div>
                  <div className="text-xl ">
                    {customer.company_name}{" "}
                    <span className="text-sm text-gray-500">(Private Limited)</span>
                  </div>
                </div>
                <div className="text-xl text-gray-500"> {customer.email}</div>
                <div className="text-xl text-gray-500">{customer.phone_number}</div>
              </div>
              <div className="text-xl text-gray-500 mt-3">
                {customer.address}
              </div>
            </div> */}
            <div className="mt-10">
              <div className="flex flex-row gap-12 justify-end">
                <div className="flex gap-3 items-center">
                  <label className="text-md text-gray-700 font-bold">Start Date:</label>
                  <input type="date" name="startDate" className="border-b-2 border-gray-400 focus:outline-none focus:border-primary" />
                </div>
                <div className="flex gap-3 items-center">
                  <label className="text-md text-gray-700 font-bold">End Date:</label>
                  <input type="date" name="endDate" className="border-b-2 border-gray-400 focus:outline-none focus:border-primary" />
                </div>
              </div>
              <table className="animate_animated animate_fadeIn border-separate border-spacing-y-4">
                <thead className="bg-primary shadow-lg border-separate-2 border-spacing-y-2">
                  <tr className="uppercase text-sm leading-normal w-full text-white">
                    <th className="rounded-l-lg p-[1.5%] text-left text-[13px] w-[1%]">
                      Date
                    </th>
                    <th className="p-[1.5%] text-left text-[13px] w-[2%]">
                      Note
                    </th>
                    <th className="p-[1.5%] text-left text-[13px] w-[2%]">
                      Reference
                    </th>
                    <th className="p-[1.5%] text-left text-[13px] w-[1%]">
                      Debit
                    </th>
                    <th className="p-[1.5%] text-left text-[13px] w-[1%]">
                      Credit
                    </th>
                    <th className="rounded-r-lg p-[1.5%] text-left text-[13px] w-[1%]">
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ledger.map((entry, index) => (
                    <tr key={index} className="rounded-lg border border-gray-400 shadow-sm shadow-gray-300">
                      <td className="py-[1%] px-[2%] text-left rounded-l-lg text-[15px] text-primary font-bold w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                        {entry.order_date ? moment(entry.order_date).format("ll") : moment(entry.date).format("ll")}
                      </td>
                      <td className="py-[1%] px-[2%] text-left text-[15px] text-gray-700 font-light w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                        {entry.note ? entry.note : "-"}
                      </td>
                      <td className="py-[1%] px-[2%] text-left text-[15px] text-gray-700 font-light w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                        {entry.type === "ORDER" ? `Order ${entry.invoice}` : `Payment for Order ${entry.invoice}`}
                      </td>
                      <td className="py-[1%] px-[2%] text-left text-[15px] font-medium w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                        {entry.type === "ORDER" ? entry.amount : "-"}
                      </td>
                      <td className="py-[1%] px-[2%] text-left text-[15px] font-medium w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                        {entry.type === "TRANSACTION" ? entry.amount : "-"}
                      </td>
                      <td className="py-[1%] px-[2%] rounded-r-lg text-left text-[15px] font-light w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                        {parseFloat(entry.balance).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex flex-row items-center justify-end gap-4 mt-3">
              <div className="text-2xl font-semibold text-primary">End Balance:</div>
              <div className="text-lg font-normal text-gray-700">{parseFloat(ledger[ledger.length - 1]?.balance).toFixed(2)}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Payment = forwardRef(({ amount }, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async pay() {
      let price = parseInt(amount.toString().replaceAll(".", ""));
      return await stripeService
        .createIntent({ price: price })
        .then(async (response) => {
          if (!stripe || !elements) {
            return { success: false };
          }

          const cardElement = elements.getElement(CardElement);

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            response.clientSecret,
            {
              payment_method: {
                card: cardElement,
              },
            }
          );

          if (error) {
            return { success: false };
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            return { success: true };
          }
        });
    },
  }));

  return (
    <CardElement
      options={{
        style: {
          base: {
            fontWeight: "700",
            fontFamily: "inherit",
            color: "#000000",
            "::placeholder": {
              color: "#afafaf",
              fontWeight: "500",
            },
          },
        },
        hidePostalCode: true,
      }}
      className={`py-3 border border-gray-200 focus:outline-none focus:border-primary  w-full px-3 bg-gray-400 bg-opacity-20 rounded-lg p-0.5`}
    />
  );
});

export default Accounts;
