import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import customerService from "../../Services/customerService";
import toast from "react-hot-toast";

const ForgotPassword = () => {

    const [email, setEmail] = useState('')
    const navigate = useNavigate()

    const forgot = async () => {
        try {
            const response = await customerService.forgot({ email })
            console.log(response)
            if (response.responseCode === 200){
                navigate("/otp")
            } else {
                toast.error("Invalid email")
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='bg-[#EBEBEB] h-screen'>
            <div className='w-[100%] flex flex-col items-center justify-center h-screen '>
                <div className='bg-white px-16 py-10 rounded-lg shadow-lg' >
                    <h1 className='text-3xl text-primary font-extrabold  text-center'>
                        Forgout Your Password
                    </h1>
                    <p className='text-md text-black mt-3'>
                        You will recieve a code through email to reset your password
                    </p>
                    <div className='flex flex-row gap-2 items-center mt-4'>
                        <label className='text-sm'> Pleae Enter Your Email </label>
                        <input
                            type='email'
                            name='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Enter Your Email'
                            className='bg-slate-50 px-2 py-0.5 border border-gray-300 focus:border-gray-400 outline-none rounded-lg '
                        />
                    </div>

                    <div className='text-center mt-5'>
                        <button className='bg-primary py-2 px-3 text-white rounded-lg'
                            onClick={() => forgot()}>
                            Get Verification Code
                        </button>
                    </div>
                    <div className='text-center mt-3'>
                        <p className='text-green-600 hover:cursor-pointer hover:text-green-500'
                            onClick={() => navigate('/login')}
                        > Back to Login </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
